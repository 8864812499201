<template>
    <div class="mct-modulos container">
        <div class="row">
            <div class="col" v-for="(anoletivo,index) in data" :key="index" @click="abrirExercicio($event,anoletivo)" :style="anoletivo.ano_codigo !== Exercicio ? 'background: #FF6666' : 'background: #379A7B'">
            
                <h1 class="mct-text mct-exe">{{ anoletivo.ano_codigo }}</h1>
                <i class="fa fa-times fa-5x" aria-hidden="true" v-if="anoletivo.ano_codigo !== Exercicio" style="color: #FF4C4C"></i>
                <i class="fa fa-check fa-5x" aria-hidden="true" v-if="anoletivo.ano_codigo === Exercicio" style="color: #318A6E"></i>
                <p class="mct-text">{{ anoletivo.ano_codigo === Exercicio ? 'ANO LETIVO ATUAL' : '' }}</p>
            
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

export default {
    data (){
        return {
            data: {}
        }
    },
    methods: {
        retornaLista () {
            var vm = this
            axios.get(`/escanoletivo/combo`)
            .then(function(response) {
                Vue.set(vm.$data, 'data', response.data.data)                
            })
            .catch(function(response) {
                console.log(response.message)
            })
        },
        abrirExercicio (ev, anoletivo) {
            if (anoletivo.ano_codigo != 0 && anoletivo.ano_codigo != null && anoletivo.ano_codigo != 'undefined')
            { 
                this.$store.dispatch('Login/USU_EXERCICIO', anoletivo.ano_codigo);
                this.$router.push({path: '/'})    
            }
        }
    },
    mounted() {
        this.retornaLista()
    },
    computed: {
        ...mapState('Login',['infoLogado','infoModulo','infoSubModulo','infoMenuSideBar','Entidade','Exercicio','Competencia'])
    },    
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=PT+Sans');

    .mct-modulos p,
    .mct-modulos h1
    {
        font-family: 'PT Sans', Helvetica, Arial, Verdana, sans-serif;
    }
    
    .mct-modulos{
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
    }

    .mct-modulos h1{
        color: #ffffff;
        font-size: 1.6em;
        font-weight: bold;
    }

    .mct-modulos p{
        width: 100%;
        color: #ffffff;
        font-size: 0.7em;
        font-weight: normal;
        position: absolute;
        bottom: 0;
    }

    .mct-modulos img{
        max-height: 100px;
    }

    .mct-modulos .col{
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        background-color: red;
        /*margin: 5px;*/
        padding: 2px;
        overflow: hidden;

        position: relative;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1); 
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1); 
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1);
        transition: all 200ms ease-in;
        transform: scale(1);          
    }

    .mct-modulos .col:hover {
        box-shadow: 1px 1px 1px #FFFFFF;
        z-index: 9999;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.2);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.2);   
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.2);
        transition: all 200ms ease-in;
        transform: scale(1.2);  
    }

    .row {
        margin: 0 auto;
    }
    
    .mct-exe{
        font-size: 3em!important;
    }
</style>
